<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title color="white">Dettaglio nota spesa</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <div class="richiesta">
          <div class="field">
            <div class="title">Data</div>
            <div class="value">
              {{ dateFormat(spesa.nota_spese_data) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Importo</div>
            <div class="value">€ {{ spesa.nota_spese_importo }}</div>
          </div>
          <div class="field">
            <div class="title">Rimborso effettuato</div>
            <div class="value">
              <div v-if="spesa.nota_spese_rimborsata == 1" class="badge badge_success">Rimborsata</div>
              <div v-if="spesa.nota_spese_rimborsata == 0" class="badge badge_danger">Non rimborsata</div>
            </div>
          </div>
          <div class="field_note">
            <div class="title">Descrizione</div>
            <div class="value">
              {{ spesa.nota_spese_descrizione ? spesa.nota_spese_descrizione : "-" }}
            </div>
          </div>
          <div class="field_ricevuta">
            <div class="title">Foto ricevuta</div>
            <div v-if="spesa.nota_spese_foto_ricevuta">
              <!-- <img :src="setImageUrl(spesa.nota_spese_foto_ricevuta)" /> -->
              <ion-img :src="setImageUrl(spesa.nota_spese_foto_ricevuta)"></ion-img>
            </div>
            <div v-else class="value">
              Non hai caricato la foto della ricevuta
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  //IonBackButton,
  IonTitle,
  IonContent,
  IonIcon,
  IonButton,
  IonImg,
  modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { dateFormat } from "../services/utils";

export default defineComponent({
  name: "NotaSpesaDetail",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonImg,
  },
  setup(props, context) {
    const route = useRoute();
    const id = route.params.id; //spesa_id by route params
    const router = useRouter();
    const loading = ref(false);
    const spesa = ref([]);

    //console.log(props.data);

    spesa.value = { ...props.data };
    //console.log(spesa.value);

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    function setImageUrl(path) {
      return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
    }

    return {
      id,
      loading,
      dateFormat,
      spesa,
      arrowBackOutline,
      closeModal,
      setImageUrl,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

.description_title {
  color: var(--ion-color-primary, #3880ff) !important;
  margin: 10px 0;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative; /* to be used for new richiesta btn */
  padding: 16px;
  min-height: 100%;
  background: #f8fafc;
}

.richiesta {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 6px;
  border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_ricevuta {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 6px;
}
.field .title,
.field_note .title,
.field_ricevuta .title {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
}
.field_ricevuta .title {
  margin-bottom: 8px;
}
.field_note .title {
  margin-bottom: 8px;
}
.field .value,
.field_note .value,
.field_ricevuta .value {
  font-size: 14px;
}

.value .badge {
  font-size: 12px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}
.value .badge_success {
  background-color: rgb(220 252 231);
  color: rgb(34 197 94);
  font-weight: bold;
}
.value .badge_danger {
  background-color: rgb(254 226 226);
  color: rgb(239 68 68);
  font-weight: bold;
}
ion-button {
  --color: #ffffff;
}
</style>
